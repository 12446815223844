<template lang="pug">
	window
		template(slot="header")
			span ¿Olvidastes tu contraseña?

		v-form
			p.
				Por favor ingresa tu correo para restablecer tu contraseña

			v-text-field(v-model.trim="form.email"
						label="Email"
						autocomplete="email"
						prepend-icon="mdi-email"

						:error-messages="emailError"

						@blur="$v.form.email.$touch()")

		v-layout(column align-center)
			v-flex
				v-btn(large color="primary" @click="request" :loading="loading", :disabled="invalid || loading") Restablecer

		div(class="text-xs-right")
			router-link(:to="{name: 'login'}") Ingresar
</template>

<script>
import {required, email} from 'vuelidate/lib/validators'
import Window from '@/modules/access/components/Window.component';

export default {
	name: 'ForgotPassword',
	data() {
		return {
			form: {
				email: '',
			}
		}
	},

	components: {
		Window
	},

	computed: {
		emailError() {
			const errors = [];
			const email = this.$v.form.email;

			if(email.$error) {
				const messages = this.errorMessages;
				if (!email.required) errors.push(messages.required)
				if (!email.email) errors.push(messages.email)
			}

			return errors
		},

		invalid() {
			return this.$v.form.$error;
		}
	},

	props: {
		loading: {
			type: Boolean,
			default: false
		},
		errorMessages: {
			type: Object,
			default() {
				return {
					email: 'Email is invalid',
					required: 'Field is required'
				};
			}
		}
	},

	methods: {
		request() {

			this.$v.form.$touch();

			if (this.$v.form.$invalid) return;

			this.$emit('request', {
				email: this.form.email,
			});

		}
	},

	validations: {
		form: {
			email: {
				required,
				email
			}
		}
	}
}
</script>

<style scoped>
	.bg-virtualitour {
		background-color: #0050b3;
	}
</style>
