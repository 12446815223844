<template lang="pug">
	forgot-password(:loading="loading" @request="request")
</template>

<script>
import ForgotPassword from '../components/ForgotPassword.component';
import firebase from '@/modules/common/firebase';

export default {
	name: 'AccessForgotPassword',
	components: {
		ForgotPassword
	},
	data() {
		return {
			loading: false
		};
	},
	methods: {
		request(user) {
			this.loading = true;
			firebase.auth().sendPasswordResetEmail(user.email)
				.then(this.redirect)
				.catch(this.notify);
		},

		redirect() {
			this.loading = false;
			this.$router.push({name: 'login'});
			this.$notify({
				title: 'Success',
				text: 'Te hemos enviado un correo para que puedas reestablecer tu contraseña',
				type: 'success'
			});
		},

		notify() {
			this.loading = false;
			this.$notify({
				title: 'Invalid',
				text: 'Correo Inválido',
				type: 'error'
			});
		}
	},
}
</script>

<style scoped>

</style>
